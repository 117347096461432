"use client";

import { useRef, useState } from "react";
import { Col, Container, Figure, Row } from "react-bootstrap";
import Footer from "../components/Footer";
import Florian from "../assets/FlorianH.jpg";
import { FaChevronLeft, FaChevronRight, FaPlay } from "react-icons/fa";
import SEO from "../components/SEO";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import truth from "../assets/5truth.mp4";
import truththumb from "../assets/5truththumb.jpg";
import whatisrethumb from "../assets/whatisrethumb.jpg";
import whatisre from "../assets/whatisre.mp4";
import welcometocomunitythumb from "../assets/weclomethumb.jpg";
import welcometocomunity from "../assets/welcome_to_community.mp4";
import propertyAppThumb from "../assets/propertyAppThumb.jpg";
import propertyApp from "../assets/propertyApp.mp4";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";

const AboutUsPage = () => {
  const { t } = useTranslation();

  const blueBallStyle = {
    width: "15px", // Set a smaller width to make it a circle
    height: "15px", // Same as width to make it a circle
    backgroundColor: "#66b2ff", // Blue color
    borderRadius: "50%", // Fully rounded to make it a circle
    padding: "10px", // Use padding to control the size of the circle
  };
  const teamMembers = [
    {
      name: "I. Bici",
      carrePosition: "coFounderBusiness",
      position: "serialEntrepreneur",
      image: "https://images.carre.ai/ilir.f24a3701dbc42ab76a9e-min.png",
    },
    {
      name: "F. Huchedé",
      carrePosition: "coFounderDeveloper",
      position: "financialExperience",
      image: Florian,
    },
    {
      name: "A. Ademi",
      carrePosition: "cto",
      position: "softwareDevelopment",
      image: "https://images.carre.ai/adem.fe35f92097c2670f3567-min.png",
    },
    // Add more team members as needed
  ];

  const team2 = [
    {
      name: "P. Lluka",
      position: "cfo",
      image: "https://images.carre.ai/Pa.636d17e58a851035db50-min.png",
    },
    {
      name: "E. Kaiku",
      position: "creativeDirector",
      image: "https://images.carre.ai/Eduart.c612be07c776e853088c-min.png",
    },
    {
      name: "J. McCausland",
      position: "vpCustomerRelations",
      image: "https://images.carre.ai/Julia.2fbb3ca6ad60946cab81-min.png",
    },
    {
      name: "A. Amzai",
      position: "frontEndDeveloper",
      image: "https://images.carre.ai/Donatello.2a973bd2d5d0d736cc19-min.png",
    },
    {
      name: "A. Jupolli",
      position: "legal",
      image: "https://images.carre.ai/AJupolli.ee194b063e85ef727ee9-min.png",
    },
    {
      name: "D. Sadikaj",
      position: "devOps",
      image: "https://images.carre.ai/Tani.bcbdeb256989f8d4dc4d-min.png",
    },
    {
      name: "M. Ademi",
      position: "backEndDeveloper",
      image: "https://images.carre.ai/Meriton.2dbbea0bc1765287b747-min.png",
    },
    {
      name: "S. Gvilava",
      position: "designer",
      image: "https://images.carre.ai/Shota.0ccdb1bda916910a6b32-min.png",
    },
  ];

  // Define values with translation keys
  const ourValues = [
    {
      key: "transparency",
      textKey: "transparencyText",
    },
    {
      key: "accessibility",
      textKey: "accessibilityText",
    },
    {
      key: "integrity",
      textKey: "integrityText",
    },
    {
      key: "innovation",
      textKey: "innovationText",
    },
    {
      key: "collaboration",
      textKey: "collaborationText",
    },
    {
      key: "empowerment",
      textKey: "empowermentText",
    },
    {
      key: "sustainability",
      textKey: "sustainabilityText",
    },
    {
      key: "customerCentricity",
      textKey: "customerCentricityText",
    },
  ];

  // const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);

  const videos = [
    {
      id: 1,
      thumbnailSrc: welcometocomunitythumb,
      videoSrc: welcometocomunity,
      title: "Welcome to Carré",
    },
    {
      id: 2,
      thumbnailSrc: truththumb,
      videoSrc: truth,
      title: "5 Truths About Carré",
    },
    {
      id: 3,
      thumbnailSrc: whatisrethumb,
      videoSrc: whatisre,
      title: "What is real estate",
    },
    {
      id: 4,
      thumbnailSrc: propertyAppThumb,
      videoSrc: propertyApp,
      title: "Property Appreciate",
    },
  ];
  const [activeVideo, setActiveVideo] = useState(null);
  const [swiperInstance, setSwiperInstance] = useState(null); // Store Swiper instance

  const handlePlayClick = (videoId) => {
    setActiveVideo(videoId);
  };

  const navigateSwiper = (direction) => {
    if (swiperInstance) {
      if (direction === "left") {
        swiperInstance.slidePrev();
      } else {
        swiperInstance.slideNext();
      }
    }
  };

  const handleVideoEnd = () => {
    if (swiperInstance) {
      const nextIndex = swiperInstance.activeIndex + 1;
      if (nextIndex < videos.length) {
        swiperInstance.slideTo(nextIndex); // Move to next slide
        setActiveVideo(videos[nextIndex].id); // Play the next video
      }
    }
  };
  return (
    <>
      <SEO
        title="About Carré | Fractional Real Estate Investment"
        description="Learn more about Carré, a platform that allows you to invest in real estate for as little as $100. Explore our vision and values."
        keywords="about Carré, real estate investment platform, fractional ownership, investment opportunities, affordable real estate, passive income"
        url="https://carre.ai/about"
      />
      <Container className="mt-5 container-sm">
        <h1 className="mb-3 text-black text-center">{t("aboutUs.title")}</h1>
        <Figure.Caption className="blockquote-footer mt-3 text-center fs-4">
          {t("aboutUs.quote")}
        </Figure.Caption>

        <h2 className="fw-bold">{t("aboutUs.ourStory.heading")}</h2>
        <Row className="align-items-center">
          <Col xl={6}>
            <p className="rounded  fs-6">{t("aboutUs.ourStory.content")}</p>
          </Col>
          <Col
            xl={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="position-relative w-100">
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={30}
                slidesPerView={1}
                pagination={{ clickable: true, el: ".custom-pagination" }}
                className="rounded-lg overflow-hidden"
                style={{ height: "614px" }}
                onSwiper={setSwiperInstance}
                onSlideChange={() => setActiveVideo(null)}
              >
                {videos.map((video, index) => (
                  <SwiperSlide key={video.id}>
                    <div style={{ height: "614px" }}>
                      {activeVideo === video.id ? (
                        <ReactPlayer
                          url={video.videoSrc}
                          playing
                          controls
                          width="100%"
                          height="100%"
                          onEnded={handleVideoEnd}
                        />
                      ) : (
                        <div
                          className="position-relative h-100"
                          style={{ cursor: "pointer" }}
                          onClick={() => handlePlayClick(video.id)}
                        >
                          <img
                            src={video.thumbnailSrc || "/placeholder.svg"}
                            alt={video.title}
                            className="w-100 h-100 object-fit-contain"
                          />
                          <FaPlay
                            className="position-absolute"
                            style={{
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              fontSize: "3rem",
                              color: "white",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              {/* Navigation Icons */}
              <FaChevronLeft
                onClick={() => navigateSwiper("left")}
                className="position-absolute"
                style={{
                  top: "50%",
                  left: "10px",
                  transform: "translateY(-50%)",
                  fontSize: "2rem",
                  cursor: "pointer",
                  zIndex: 1000,
                  color: "#666",
                }}
              />
              <FaChevronRight
                onClick={() => navigateSwiper("right")}
                className="position-absolute"
                style={{
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  fontSize: "2rem",
                  cursor: "pointer",
                  zIndex: 1000,
                  color: "#666",
                }}
              />

              {/* Pagination Dots */}
              <div className="custom-pagination mt-3 text-center"></div>
            </div>
          </Col>
        </Row>
        <h2 className="fw-bold my-3 pt-3">{t("aboutUs.ourMission.heading")}</h2>
        <Row className="align-items-center">
          <Col xl={12}>
            <ul className="list-unstyled">
              {[0, 1, 2, 3].map((index) => (
                <li key={index} className="d-flex align-items-center mb-3">
                  <div style={blueBallStyle} className="me-3"></div>
                  <span>{t(`aboutUs.ourMission.items.${index}`)}</span>
                </li>
              ))}
            </ul>
          </Col>
        </Row>

        <h2 className="fw-bold my-3 pt-3">{t("aboutUs.ourTeam.heading")}</h2>
        <Row>
          {teamMembers.map((teamMember, index) => (
            <Col key={index} xl={4} className="my-3 d-flex">
              <div className="d-flex flex-column align-items-center w-100 h-100 bg-carre-gray rounded p-3">
                <div className="flex-shrink-0 mb-4">
                  <img
                    src={teamMember.image || "/placeholder.svg"}
                    alt={teamMember.name}
                    className="rounded-circle"
                    style={{ width: "150px", height: "150px" }}
                  />
                </div>
                <div className="text-center">
                  <h5 className="fw-bold mb-0 text-carre-blue">
                    {teamMember.name}
                  </h5>
                  <p className="fw-bold mb-0 mt-1">
                    {t(`aboutUs.ourTeam.positions.${teamMember.carrePosition}`)}
                  </p>
                  <p className="text-muted mb-0">
                    {t(`aboutUs.ourTeam.positions.${teamMember.position}`)}
                  </p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          {team2.map((teamMember, index) => (
            <Col key={index} xl={4} md={6} className="my-3">
              <div className="d-flex align-items-center mb-4 rounded p-3">
                <div className="flex-shrink-0">
                  <img
                    src={teamMember.image || "/placeholder.svg"}
                    alt={teamMember.name}
                    className="rounded-circle"
                    style={{ width: "150px", height: "150px" }}
                  />
                </div>
                <div className="ms-3">
                  <h5 className="fw-bold mb-0 text-carre-blue">
                    {teamMember.name}
                  </h5>
                  <p className="fw-bold mb-0">
                    {t(`aboutUs.ourTeam.positions.${teamMember.position}`)}
                  </p>
                </div>
              </div>
            </Col>
          ))}
        </Row>

        <h2 className="fw-bold text-center my-3">
          {t("aboutUs.ourValues.heading")}
        </h2>
        <Row>
          {ourValues.map((value, index) => (
            <Col key={index} xl={6}>
              <div className="bg-carre-gray p-3 rounded mb-3">
                <p className="text-carre-blue fw-bold h5">
                  {t(`aboutUs.ourValues.values.${value.key}`)}
                </p>
                <p>{t(`aboutUs.ourValues.${value.textKey}`)}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default AboutUsPage;
