import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { FaGlobe } from "react-icons/fa"; // Import a white globe icon

// Custom toggle component for dropdown
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="d-flex align-items-center justify-content-center py-2 bg-transparent border-0 shadow-none cursor-pointer"
  >
    <FaGlobe className="text-white me-2" size={18} /> {/* White Globe Icon */}
    {children}
  </div>
));

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const languages = {
    en: { label: "English" },
    fr: { label: "Français" },
    tr: { label: "Türkçe" },
  };

  const handleSelect = useCallback(
    (eventKey) => {
      if (i18n.language !== eventKey) {
        i18n.changeLanguage(eventKey).then(() => {
          localStorage.setItem("language", eventKey);
        });
      }
    },
    [i18n]
  );

  return (
    <Dropdown onSelect={handleSelect} className="w-100 pt-3">
      <Dropdown.Toggle as={CustomToggle} id="language-switcher">
        <span className="text-white fw-bold w-100">{languages[i18n.language]?.label || "English"}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu align="end" className="rounded-lg shadow-lg bg-white border-0  w-100">
        {Object.entries(languages).map(([code, { label }]) => (
          <Dropdown.Item
            key={code}
            eventKey={code}
            active={i18n.language === code}
            className="d-flex align-items-center px-3 py-2 text-dark fw-bold hover:bg-light transition-all"
          >
            {label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

CustomToggle.displayName = "CustomToggle";
export default LanguageSwitcher;
