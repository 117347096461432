import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { IoCheckmark } from "react-icons/io5";
import {
  FcBullish,
  FcElectricity,
  FcLandscape,
  FcPieChart,
} from "react-icons/fc";

const WhyUsSection = () => {
  const { t } = useTranslation();

  return (
    <section className="container py-3 my-md-2 my-lg-3 my-xl-4 my-xxl-4">
      <div className="row align-items-center py-1 py-sm-3 py-md-4 my-lg-2">
        <div className="col-lg-4 offset-xl-1">
          <h2 className="h1 text-center text-lg-start pb-3 pb-lg-1 fw-semibold">
            {t("why_us.title")}
          </h2>
          <ul className="list-unstyled pb-3 mb-3 mb-lg-4">
            <li className="d-flex pt-2">
              <IoCheckmark className="ai-check-alt fs-4 mr-2 me-2 text-success" />
              {t("why_us.bullet_1")}
            </li>
            <li className="d-flex pt-2">
              <IoCheckmark className="ai-check-alt fs-4 mr-2 me-2 text-success" />
              {t("why_us.bullet_2")}
            </li>
            <li className="d-flex pt-2">
              <IoCheckmark className="ai-check-alt fs-4 mr-2 me-2 text-success" />
              {t("why_us.bullet_3")}
            </li>
          </ul>
        </div>
        <div className="col-lg-8 col-xl-7 col-xxl-6">
          <div className="row row-cols-1 row-cols-sm-2">
            <div className="col">
              <div className="card border-0 bg-primary bg-opacity-10">
                <div className="card-body">
                  <FcPieChart size={35} className="mb-2 text-primary" />
                  <h3 className="h4 fw-semibold">
                    {t("why_us.fractional_ownership.title")}
                  </h3>
                  <p className="fs-sm">
                    {t("why_us.fractional_ownership.description")}
                  </p>
                </div>
              </div>
              <div className="card border-0 bg-info bg-opacity-10 mt-4">
                <div className="card-body">
                  <FcBullish size={35} className="mb-2 text-primary" />
                  <h3 className="h4 fw-semibold">
                    {t("why_us.profitable_investment.title")}
                  </h3>
                  <p className="fs-sm">
                    {t("why_us.profitable_investment.description")}
                  </p>
                </div>
              </div>
            </div>
            <div className="col pt-lg-3">
              <div className="card border-0 bg-warning bg-opacity-10 mt-4 mt-sm-0 mt-lg-4">
                <div className="card-body">
                  <FcElectricity size={35} className="mb-2 text-primary" />
                  <h3 className="h4 fw-semibold">
                    {t("why_us.flexibility.title")}
                  </h3>
                  <p className="fs-sm">{t("why_us.flexibility.description")}</p>
                </div>
              </div>
              <div className="card border-0 bg-danger bg-opacity-10 mt-4">
                <div className="card-body">
                  <FcLandscape size={35} className="mb-2 text-primary" />
                  <h3 className="h4 fw-semibold">
                    {t("why_us.vacation_points.title")}
                  </h3>
                  <p className="fs-sm">
                    {t("why_us.vacation_points.description")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUsSection;
