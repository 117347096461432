import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Nav, Navbar } from "react-bootstrap";
import { AiOutlineUser } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/TokenCreation";
import { useTabContext } from "../pages/Dashboard/TabContext";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import VerificationUser from "../pages/VerificationUser";
import LogoNav from "./LogoNavbar";
import LanguageSwitcher from "./LanguageSwitcher";

function NavBar({ isHome }) {
  const { t } = useTranslation();
  const [modalShowVerifyUser, setModalShowVerifyUser] = useState(false);
  const {
    loginModalOpen,
    setLoginModalOpen,
    signUpModalOpen,
    setSignUpModalOpen,
  } = useTabContext();

  const openLoginFromSignUp = () => {
    setSignUpModalOpen(false);
    setLoginModalOpen(true);
  };

  const openLoginFromVerify = () => {
    setModalShowVerifyUser(false);
    setLoginModalOpen(true);
  };

  const openSignUpFromLogin = () => {
    setLoginModalOpen(false);
    setSignUpModalOpen(true);
  };

  const { cookies } = useAuth();
  const nav = useNavigate();

  return (
    <div className="w-100">
      <Navbar fixed={"top"} expand="lg" style={{ borderBottom: "0", height: "4.5rem", backgroundColor: "#f4f7fa" }}>
        <Login
          show={loginModalOpen}
          onHide={() => setLoginModalOpen(false)}
          openSignUpFromLogin={openSignUpFromLogin}
        />

        <SignUp
          show={signUpModalOpen}
          onHide={() => setSignUpModalOpen(false)}
          openLoginFromSignUp={openLoginFromSignUp}
          showVerfiyUser={() => setModalShowVerifyUser(true)}
        />

        <VerificationUser
          show={modalShowVerifyUser}
          onHide={() => setModalShowVerifyUser(false)}
          openLoginFromVerify1={openLoginFromVerify}
          isDashboard={false}
        />

        <div className="container " style={{ backgroundColor: "#f4f7fa" }}>
          <Navbar.Brand href="/" aria-label="Home">
            <LogoNav />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" aria-label="Toggle navigation" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto gap-lg-4 mt-4 mt-lg-0">
              <Nav.Link href="/" className="fw-bold">
                {t("navigation.home")}
              </Nav.Link>
              <Nav.Link href="/marketplace" className="fw-semibold">
                {t("navigation.marketplace")}
              </Nav.Link>
              <Nav.Link href="/aboutuspage" className="fw-semibold">
                {t("navigation.about_us")}
              </Nav.Link>
              <Nav.Link href="https://learn.carre.ai/" target="_blank" className="fw-semibold">
                {t("navigation.learn")}
              </Nav.Link>
            </Nav>

            <Navbar.Text>
              <div className="mt-3 mt-lg-0 mb-3 mb-lg-0">
                <Button
                  className="align-items-center text-black rounded-4"
                  variant="outline-none"
                  style={{ marginRight: "11px" }}
                  onClick={() => (cookies.token ? nav(`/user`) : setLoginModalOpen(true))}
                >
                  <AiOutlineUser className="mx-1" size="20" /> {t("navigation.login")}
                </Button>

                <Button className="align-items-center rounded-4" variant="warning" onClick={() => setSignUpModalOpen(true)}>
                  + {t("navigation.signup")}
                </Button>
              </div>
            </Navbar.Text>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  );
}

export default NavBar;
