import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Offcanvas,
  Placeholder,
  Row,
} from "react-bootstrap";
import { BiReset } from "react-icons/bi";
import { MdOutlineFilterAlt } from "react-icons/md";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import { useGetMarketPlace } from "../hooks/useGetMarketPlace";
import { useGetPropertyPublic } from "../hooks/useGetProperty";
import { useGetPropertyCountries } from "../hooks/useGetPropertyCountries";
import { useGetPropertyType } from "../hooks/useGetPropertyType";
import FinderPropertyCard from "./Property/FinderPropertyCard";
import SEO from "../components/SEO";
import { useTranslation } from "react-i18next";

function MarketPlaceHome() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [market, setMarket] = useState("");
  const [location, setLocation] = useState("");
  const [type, setType] = useState("");
  const nav = useNavigate();
  const navigate = (id) => {
    nav("propdetails/" + id);
  };
  const handleResetFilters = () => {
    setMarket("");
    setLocation("");
    setType("");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { data: marketPlace, isLoading: isLoadingMarketPlace } =
    useGetMarketPlace();
  const { data, isLoading } = useGetPropertyPublic({ market, location, type });
  const { data: propertyType, isLoading: isLoadingPropertyType } =
    useGetPropertyType();

  const { data: country, isLoading: isLoadingCountry } =
    useGetPropertyCountries();

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries("property-public");
  }, [market, location, type, queryClient]);

  const typePrimaryItems = [];
  const typeUpcomingItems = [];
  const typeSecondaryItems = [];
  const typeOtherItems = [];

  data?.forEach((item) => {
    if (item.market_stage_name === "Primary") typePrimaryItems.push(item);
    else if (item.market_stage_name === "Upcoming")
      typeUpcomingItems.push(item);
    else if (item.market_stage_name === "Secondary")
      typeSecondaryItems.push(item);
    else typeOtherItems.push(item);
  });
  const combinedData = [
    ...typePrimaryItems,
    ...typeUpcomingItems,
    ...typeSecondaryItems,
    ...typeOtherItems,
  ];

  const {t} = useTranslation()

  return (
    <>
      <SEO
        title="Discover Premium Real Estate Investments | Carré Marketplace"
        description="Explore the Carré Marketplace for exclusive fractional real estate investment opportunities. Join today and invest in high-quality rental properties with as little as $100."
        keywords="real estate marketplace, fractional investments, property investment, Carré marketplace, rental properties, investment opportunities, affordable real estate, passive income"
        url="https://carre.ai/marketplace"
      />
      <NavBar />

      <Container
        style={{
          marginTop: "66px",
        }}
      >
        <Row className="">
          <Col
            as="aside"
            lg={4}
            xl={3}
            className="border-end px-3 px-xl-4 px-xxl-5 pt-lg-2"
          >
            <Offcanvas
              show={show}
              onHide={handleClose}
              responsive="lg"
              className=""
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title as="h5">{t("filters.title")}</Offcanvas.Title>
              </Offcanvas.Header>

              <Offcanvas.Header className="d-block pt-0 pt-lg-4 px-lg-0 ">
                <Offcanvas.Title as="h5">{t("filters.marketStages")}</Offcanvas.Title>
                <div className="flex space-x-2 mb-4">
                  <Button
                    variant="outline-carre-blue w-100"
                    size="sm"
                    className={`${
                      market === "" ? "bg-carre-blue text-white" : ""
                    } px-4 py-2 rounded-lg hover:bg-blue-500 hover:text-white transition-all duration-300 mt-2 mx-1`}
                    onClick={() => setMarket("")}
                  >
{t("filters.allProperties")}                  </Button>

                  {marketPlace?.map((stage) =>
                    stage.name !== "Submitted" ? (
                      <Button
                        variant="outline-carre-blue w-100"
                        size="sm"
                        key={stage.id}
                        className={`${
                          stage.id === market ? "bg-carre-blue text-white" : ""
                        } px-4 py-2 rounded-lg hover:bg-blue-500 hover:text-white transition-all duration-300 mx-1 mt-2`}
                        onClick={() => setMarket(stage.id)}
                      >
                        {stage.name}
                      </Button>
                    ) : null
                  )}
                </div>
              </Offcanvas.Header>

              <Offcanvas.Body className="py-lg-4 ">
                <div className="pb-4 mb-2 ">
                  <h3 className="h6">{t("filters.location")}</h3>
                  <select
                    className=" form-select"
                    aria-label="Default select example"
                    onChange={(e) => setLocation(e.target.value)}
                  >
                    <option selected value={""}>
                    {t("filters.location")}                    </option>
                    {country?.map((country) => (
                      <option key={country.name} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="pb-4 mb-2">
                  <h3 className="h6">{t("filters.propertyType")}</h3>
                  <select
                    className=" form-select"
                    aria-label="Default select example"
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option selected value={""}>
                    {t("filters.propertyType")}                    </option>
                    {propertyType?.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="border-top py-4">
                  <Button
                    variant="outline-carre-blue w-100"
                    onClick={handleResetFilters}
                  >
                    <BiReset className="fi-rotate-right me-2"></BiReset>
                    {t("filters.resetFilters")}                  </Button>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>

          <Col
            lg={8}
            xl={9}
            className="position-relative pb-5 pt-4 px-3 px-xl-4 px-xxl-5 overflow-auto"
            style={{ maxHeight: "max" }}
          >
            <Row className=" align-items-center">
              {isLoading ? (
                <div className="row">
                  {[1, 2, 3, 4, 5, 6].map((p) => (
                    <Col
                      key={p}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={4}
                      style={{ marginBottom: "20px" }}
                    >
                      <Card>
                        <div
                          style={{
                            width: "100%",
                            height: "300px",
                            backgroundColor: "#e0e0e0",
                            marginBottom: "20px",
                            borderTopLeftRadius: 12,
                            borderTopRightRadius: 12,
                          }}
                        />
                        <Card.Body>
                          <Placeholder as={Card.Title} animation="glow">
                            <Placeholder xs={8} />
                          </Placeholder>
                          <Placeholder
                            as={Card.Text}
                            animation="glow"
                            className="mb-4"
                          >
                            <Placeholder xs={12} />
                          </Placeholder>
                          <Placeholder
                            as={Card.Text}
                            animation="glow"
                            className="justify-content-between d-flex "
                          >
                            <Placeholder xs={5} /> <Placeholder xs={5} />
                          </Placeholder>
                          <Placeholder
                            as={Card.Text}
                            animation="glow"
                            className="justify-content-between d-flex"
                          >
                            <Placeholder xs={6} /> <Placeholder xs={4} />
                          </Placeholder>
                        </Card.Body>
                        <Card.Footer>
                          <Placeholder
                            animation="glow"
                            className="d-flex justify-content-around align-content-center"
                          >
                            <Placeholder xs={2} />
                            <Placeholder xs={2} />
                            <Placeholder xs={2} />
                          </Placeholder>
                        </Card.Footer>
                      </Card>
                    </Col>
                  ))}
                </div>
              ) : (
                <>
                  {data && data.length > 0 ? (
                    data.map((p) => (
                      <Col
                        onClick={() => navigate(p.id)}
                        key={p.id}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={4}
                        style={{ marginBottom: "20px" }}
                        className=" "
                      >
                        <FinderPropertyCard
                          price={
                            p?.market_stage_name === "Upcoming"
                              ? p?.allowed_token_to_reserve - p?.reserved_token
                              : p?.available_token?.toLocaleString("en-US")
                          }
                          badges={[
                            ["success", "Verified"],
                            ["info", "New"],
                          ]}
                          footer={[
                            ["fi-bed", 3],
                            ["fi-bath", 1],
                            ["fi-car", 2],
                          ]}
                          // light // Optionally pass light prop so the card works well on dark backgrounds
                          className="mx-auto shadow"
                          style={{ maxWidth: "800px" }}
                          data={p}
                        />
                      </Col>
                    ))
                  ) : (
                    <Alert className="text-center fs-sm p-2 fw-semibold">
                      No data is found!
                    </Alert>
                  )}
                </>
              )}
            </Row>

            {/* Pagination */}
            {/* <nav className='border-top pb-md-4 pt-4' aria-label='Pagination'>
              <Pagination className='mb-1'>
                <Pagination.Item active>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item>{8}</Pagination.Item>
                <Pagination.Item>
                  <i className='fi-chevron-right'></i>
                </Pagination.Item>
              </Pagination>
            </nav> */}
          </Col>
        </Row>
      </Container>

      <Button
        size="sm"
        variant="carre-blue"
        className="w-100 rounded-0 fixed-bottom d-lg-none "
        onClick={handleShow}
      >
        <MdOutlineFilterAlt className="fi-rotate-right me-2"></MdOutlineFilterAlt>
        Filters
      </Button>
    </>
  );
}

export default MarketPlaceHome;
