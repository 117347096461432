import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import signup from "../assets/img/signuphowitwork.png";
import explore from "../assets/img/explore.png";
import invest from "../assets/img/invest.png";
import earn from "../assets/img/earn.png";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { FiLogIn } from "react-icons/fi";
import { TbHomeSearch } from "react-icons/tb";
import { RxRocket } from "react-icons/rx";
import { FaRegCheckCircle } from "react-icons/fa";

const HowItWorksPage = () => {
  const { t } = useTranslation();
  const [activeSlide, setActiveSlide] = useState(1);
  const swiperRef = useRef(null);

  const handleSlideChange = (swiper) => {
    setActiveSlide(swiper.activeIndex + 1);
  };

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.on("slideChange", () =>
        handleSlideChange(swiperRef.current.swiper)
      );
    }
  }, []);

  return (
    <section className="container py-3 my-lg-3 my-xl-4 my-xxl-3">
      <h2 className="h1 text-center pb-3 pt-2 pt-sm-3 pt-md-4 pt-lg-4 mt-md-3 mt-lg-0 mb-3 mb-lg-4 fw-semibold mb-2 pb-4">
        {t("how_it_works.title")}
      </h2>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 gy-4 gy-sm-5 gx-4 pb-3 pb-md-4 pb-lg-5 mb-md-3 mb-lg-0">
        <div className="col text-center">
          <div
            className="ratio ratio-1x1 position-relative mx-auto mb-3 mb-sm-4"
            style={{ width: 68 }}
          >
            <FiLogIn className="p-3 text-primary d-flex align-items-center justify-content-center position-absolute start-0" />
            <svg
              className="position-absolute top-0 start-0 text-primary"
              width={68}
              height={68}
              viewBox="0 0 68 68"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M56.0059 60.5579C44.1549 78.9787 18.0053 58.9081 6.41191 46.5701C-2.92817 35.5074 -2.81987 12.1818 11.7792 3.74605C30.0281 -6.79858 48.0623 7.40439 59.8703 15.7971C71.6784 24.1897 70.8197 37.5319 56.0059 60.5579Z"
                fillOpacity="0.1"
              />
            </svg>
          </div>
          <h3 className="h4 pb-2 mb-1 fw-semibold">
            {t("how_it_works.create_account.title")}
          </h3>
          <p className="fs-sm mb-0">
            {t("how_it_works.create_account.description")}
          </p>
        </div>
        <div className="col text-center">
          <div
            className="ratio ratio-1x1 position-relative mx-auto mb-3 mb-sm-4"
            style={{ width: 68 }}
          >
            <TbHomeSearch className="p-3 text-primary d-flex align-items-center justify-content-center position-absolute start-0" />
            <svg
              className="position-absolute top-0 start-0 text-primary"
              width={68}
              height={68}
              viewBox="0 0 68 68"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M65.0556 29.2672C75.4219 46.3175 48.5577 59.7388 33.8299 64.3181C21.0447 67.5599 1.98006 58.174 0.888673 42.8524C-0.475555 23.7004 18.3473 14.5883 29.9289 8.26059C41.5104 1.93285 52.0978 7.9543 65.0556 29.2672Z"
                fillOpacity="0.1"
              />
            </svg>
          </div>
          <h3 className="h4 pb-2 mb-1 fw-semibold">
            {t("how_it_works.browse_properties.title")}
          </h3>
          <p className="fs-sm mb-0">
            {t("how_it_works.browse_properties.description")}
          </p>
        </div>
        <div className="col text-center">
          <div
            className="ratio ratio-1x1 position-relative mx-auto mb-3 mb-sm-4"
            style={{ width: 68 }}
          >
            <FaRegCheckCircle className="p-3 text-primary d-flex align-items-center justify-content-center position-absolute start-0" />
            <svg
              className="position-absolute top-0 start-0 text-primary"
              width={68}
              height={68}
              viewBox="0 0 68 68"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.61057 18.2783C10.8205 -1.22686 39.549 7.51899 53.3869 14.3301C64.8949 20.7749 72.2705 40.7038 62.5199 52.5725C50.3318 67.4085 30.4034 61.0689 17.6454 57.6914C4.88745 54.314 1.3482 42.6597 6.61057 18.2783Z"
                fillOpacity="0.1"
              />
            </svg>
          </div>
          <h3 className="h4 pb-2 mb-1 fw-semibold">
            {t("how_it_works.invest.title")}
          </h3>
          <p className="fs-sm mb-0">{t("how_it_works.invest.description")}</p>
        </div>
        <div className="col text-center">
          <div
            className="ratio ratio-1x1 position-relative mx-auto mb-3 mb-sm-4"
            style={{ width: 68 }}
          >
            <RxRocket className="p-3 text-primary d-flex align-items-center justify-content-center position-absolute start-0" />
            <svg
              className="position-absolute top-0 start-0 text-primary"
              width={68}
              height={68}
              viewBox="0 0 68 68"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.34481 53.5078C-7.24653 42.4218 11.4487 18.9206 22.8702 8.55583C33.0946 0.223307 54.3393 0.690942 61.7922 14.1221C71.1082 30.9111 57.886 47.1131 50.0546 57.7358C42.2233 68.3586 30.084 67.3653 9.34481 53.5078Z"
                fillOpacity="0.1"
              />
            </svg>
          </div>
          <h3 className="h4 pb-2 mb-1 fw-semibold">
            {t("how_it_works.earn.title")}
          </h3>
          <p className="fs-sm mb-0">{t("how_it_works.earn.description")}</p>
        </div>
      </div>
    </section>
  );
};

export default HowItWorksPage;
