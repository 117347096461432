import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next"; // Import translation hook
import {
  FaChartLine,
  FaDollarSign,
  FaGlobe,
  FaLaptop,
  FaLock,
  FaMoneyBillWave,
  FaPlane,
  FaTools,
} from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";

const FeatureSection = () => {
  const { t } = useTranslation(); // Use translation hook

  const features = [
    {
      icon: <FaMoneyBillWave size={40} />,
      title: t("features.fractional_ownership.title"),
      description: t("features.fractional_ownership.description"),
    },
    {
      icon: <FaDollarSign size={40} />,
      title: t("features.daily_rental_income.title"),
      description: t("features.daily_rental_income.description"),
    },
    {
      icon: <FaChartLine size={40} />,
      title: t("features.property_appreciation.title"),
      description: t("features.property_appreciation.description"),
    },
    {
      icon: <FaPlane size={40} />,
      title: t("features.vacation_points.title"),
      description: t("features.vacation_points.description"),
    },
    {
      icon: <FaDollarSign size={40} />,
      title: t("features.low_entry_costs.title"),
      description: t("features.low_entry_costs.description"),
    },
    {
      icon: <FaGlobe size={40} />,
      title: t("features.global_property_portfolio.title"),
      description: t("features.global_property_portfolio.description"),
    },
    {
      icon: <FaLaptop size={40} />,
      title: t("features.user_friendly_platform.title"),
      description: t("features.user_friendly_platform.description"),
    },
    {
      icon: <FaTools size={40} />,
      title: t("features.hassle_free_management.title"),
      description: t("features.hassle_free_management.description"),
    },
    {
      icon: <FaLock size={40} />,
      title: t("features.secure_transactions.title"),
      description: t("features.secure_transactions.description"),
    },
  ];

  return (
    <Container className="my-5">
      <h2 className="text-center mb-4 text-3xl fw-semibold mb-4">
        {t("features.title")}
      </h2>
      <div className="my-5 py-1 pb-5 container d-block d-sm-none">
        <Swiper
          slidesPerView={1.2}
          spaceBetween={24}
          loop={true}
          pagination={{ el: "#property-bullets", clickable: true }}
          breakpoints={{
            560: { slidesPerView: 2.2 },
            960: { slidesPerView: 3.2 },
          }}
        >
          {features.map((feature, i) => (
            <SwiperSlide key={i}>
              <Card
                className="h-auto text-start border-0 rounded-4"
                style={{ backgroundColor: "#f3f2f6", minHeight: "330px" }}
              >
                <Card.Body className="p-4">
                  <div
                    className="h4 fw-semibold mb-4"
                    style={{ color: "rgba(44,138,197,0.76)" }}
                  >
                    {feature.icon}
                  </div>
                  <Card.Title>
                    <p className="h4 fw-semibold">{feature.title}</p>
                  </Card.Title>
                  <Card.Text>{feature.description}</Card.Text>
                </Card.Body>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="d-none d-sm-block">
        <Row>
          {features.map((feature, index) => (
            <Col md={4} className="mb-4" key={index}>
              <Card
                className="h-100 text-start border-0 rounded-4"
                style={{ backgroundColor: "#f3f2f6" }}
              >
                <Card.Body className="p-5">
                  <div
                    className="h4 fw-semibold mb-5"
                    style={{ color: "rgba(44,138,197,0.76)" }}
                  >
                    {feature.icon}
                  </div>
                  <Card.Title>
                    <p className="h4 fw-semibold">{feature.title}</p>
                  </Card.Title>
                  <Card.Text>{feature.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
};

export default FeatureSection;
